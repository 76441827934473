import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import './main.css'
import { Elm } from './Main.elm'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import cuid from 'cuid'
import bowser from 'bowser'

if (bowser.msie || bowser.msedge) {
  alert(
    'Tipskampen kräver en modern webbläsare som t.ex. Chrome, Safari eller Firefox.'
  )
} else {
  const app = Elm.Main.init({node: document.getElementById('app')})

  const provider = new firebase.auth.GoogleAuthProvider()
  firebase.initializeApp({
    apiKey: 'AIzaSyDdaictsyWYh5Clq83YTlV9J-Ld3x9gsOs',
    authDomain: 'tipskampen5.firebaseapp.com',
    databaseURL: 'https://tipskampen5.firebaseio.com',
    projectId: 'tipskampen5',
    storageBucket: 'tipskampen5.appspot.com',
    messagingSenderId: '739842333985'
  })
  const db = firebase.firestore()
  const getFirstPartOfEmail = (email) => {
    if (!email || !email.split) {
      return "No Name"
    }

    const parts = email.split("@")
    if (!parts[0]) {
      return "No Name"
    }
    return parts[0]
  }

  const actionHandlers = {
    GoogleLoginAction: _ => {
      firebase.auth().signInWithRedirect(provider)
    },
    FirebaseLogoutAction: _ => {
      firebase.auth().signOut()
    },
    SaveBet: bet => {
      const sfDocRef = db.collection('bets').doc(bet.id)
      console.warn(
        'TODO',
        'If bet exists, only update homeTeam or awayTeam value'
      )
      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            transaction.set(sfDocRef, bet)
          })
        })
        .then(() => {
          console.log('Bet Transaction successfully committed!')
        })
        .catch(error => {
          console.log('Bet Transaction failed: ', error)
          app.ports.infoForElm.send({
            action: 'SaveBetError',
            data: bet.matchId
          })
        })
    },
    SaveMatchAction: match => {
      if (!match.id || match.id === '') {
        match.id = cuid()
      }
      console.log('saveMatchAction', match)
      db
        .collection('matches')
        .doc(match.id)
        .set(match, { merge: true })
        .then(_ => {
          app.ports.infoForElm.send({
            action: 'SaveMatchFormSuccess',
            data: {}
          })
        })
        .catch(e => {
          console.log('Failed to save match', e)
          app.ports.infoForElm.send({
            action: 'SaveMatchFormError',
            data: {}
          })
        })
    },
    SaveMatchResult: matchResult => {
      if (!matchResult.matchId) {
        console.warn('SaveMatchResult - missing matchId', matchResult)
        return
      }
      const sfDocRef = db.collection('matchResults').doc(matchResult.matchId)

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            transaction.set(sfDocRef, matchResult)
          })
        })
        .then(() => {
          app.ports.infoForElm.send({
            action: 'SaveMatchResultFormSuccess',
            data: {}
          })
        })
        .catch(error => {
          console.log('MatchResult Transaction failed: ', error)
        })
    },
    GetMatchBets: matchId => {
      console.log('get bets', matchId)
      db
        .collection('bets')
        .where('matchId', '==', matchId)
        .onSnapshot(querySnapshot => {
          const docs = querySnapshot.docs.map(doc => doc.data())
          app.ports.infoForElm.send({
            action: 'MatchBetsChanged',
            data: docs
          })
        })
    },
    FirebaseInitAction: _ => {
      firebase.auth().onAuthStateChanged(function(user) {
        console.log('user', user)
        const userData = user
          ? {
              uid: user.uid,
              displayName: user.displayName || getFirstPartOfEmail(user.email),
              photoURL: user.photoURL,
              email: user.email
            }
          : {}
        app.ports.infoForElm.send({
          action: 'AuthStateChanged',
          data: userData
        })

        if (user) {
          db
            .collection('users')
            .doc(user.uid)
            .onSnapshot(querySnapshot => {
              const userData = querySnapshot.data()
              db
                .collection('admins')
                .doc(user.uid)
                .get()
                .then(adminSnapshot => {
                  userData.admin = adminSnapshot.data().admin
                  app.ports.infoForElm.send({
                    action: 'AuthStateChanged',
                    data: userData
                  })
                })
                .catch(e => {
                  app.ports.infoForElm.send({
                    action: 'AuthStateChanged',
                    data: querySnapshot.data()
                  })
                })
            })
          db
            .collection('users')
            .doc(user.uid)
            .set(userData, { merge: true })

          db.collection('matches')
            .orderBy('date', 'asc')
            .onSnapshot(querySnapshot => {
              const docs = querySnapshot.docs.map(doc => doc.data())
              app.ports.infoForElm.send({
                action: 'MatchesChanged',
                data: docs
              })
            })

          db
            .collection('bets')
            .where('userId', '==', user.uid)
            .onSnapshot(querySnapshot => {
              const docs = querySnapshot.docs.map(doc => doc.data())
              app.ports.infoForElm.send({
                action: 'BetsChanged',
                data: docs
              })
            })

          db
            .collection('leaderboards')
            .orderBy('timestamp', 'desc')
            .limit(2)
            .onSnapshot(querySnapshot => {
              const docs = querySnapshot.docs.map(doc => doc.data())
              console.log('leaderboards', docs)
              const currentLeaderboard = docs[0]
              const prevLeaderboard = docs[1]
              if (!currentLeaderboard) {
                return false
              }
              const leaderboard = Object.assign(
                {},
                currentLeaderboard,
                {
                  data: currentLeaderboard.data.map(player => {
                    const prevPlayer = prevLeaderboard ? prevLeaderboard.data.filter(prevPlayer => prevPlayer.userId === player.userId)[0] : undefined
                    if (prevPlayer) {
                      return Object.assign(player, {
                        prevPosition: prevPlayer.position
                      })
                    } else {
                      return Object.assign(player, {
                        prevPosition: player.position
                      })
                    }
                  })
                }
              )

              app.ports.infoForElm.send({
                action: 'LeaderboardChanged',
                data: Object.assign(
                    {},
                    leaderboard,
                    {
                        timestamp: new Date(leaderboard.timestamp).getTime()
                    }
                )
              })

            })

          db.collection('users').onSnapshot(querySnapshot => {
            const docs = querySnapshot.docs.map(doc => doc.data())
            app.ports.infoForElm.send({
              action: 'UsersChanged',
              data: docs
            })
          })
        }
      })
    }
  }
  app.ports.infoForOutside.subscribe(msg => {
    const func = actionHandlers[msg.action]
    if (func) {
      func(msg.data)
    }
  })
}
